:root {
    --padding: 1rem; 
    --margin: .8rem; 
    --vertical-padding: 1rem; 
    --vertical-margin: .8rem;
    
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    
    --fixed-header-height: 10vh;
    
    /* Slick */
    --slick-slider-height: 45vh;
    
    /* Colors */
    --text-color: #565656;
    --main-color: #183446;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: #fff;
    
    --menu-bg-color: inherit;
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: #E20613;
    --menu-text-bg-hover-color: rgba(255,255,255,0.5);
    
    
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;

}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Jura.css";
@import "/fontmodules/Spinnaker.css";

/*
@import "/cssmodules/modules/photoswipe.css";
@import "/cssmodules/modules/animate.css";
*/

* {
    letter-spacing: 0 !important;
}

.flex img {
width:100%;

}

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: Spinnaker, sans-serif;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
    background: #f6f6f6;
}
.pdf{
    background:#183446;
    padding:.2em 1em;
    font-size: 1.2rem;
}
a.pdf{
    color:#fff;
}

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

header {
    background: var(--main-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
    z-index: 100;
        font-family: Jura, sans-serif;
}

footer {
    text-align: center;
    padding: 1rem 0;
     color: #fff;
     background: var(--main-color);
     font-family: Jura, sans-serif;
}

header a {
    text-decoration: none;
}

.logo {
font-size: calc(1 * var(--scale-factor) * var(--scale-factor))rem;
}

.logo span {
display: block;

}

.logo span:last-of-type {
display: block;
font-size: calc(1 * 1)rem;
}

article a {

color: #39749a;
}
        
/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
        font-family: Jura, sans-serif;
    text-transform: uppercase;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
        font-family: Jura, sans-serif;
}

h3 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
        font-family: Jura, sans-serif;
}
/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
background: var(--menu-bg-color);
}



.menu.menu li a{

    background: none;
    padding:.5rem .8rem;
    color: var(--menu-text-color);
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
}

.menu.menu  li.active > a, .menu.menu li a:hover{

    border-bottom: 3px solid var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
}

.menu li {

    margin: 0;

}

.submenu li a {

    background: var(--main-color);
    padding:.5rem .8rem;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 3px;
    text-decoration: none;
}

.submenu  li.active > a, .submenu li a:hover{

    background: #39749a;
    color: var(--menu-text-hover-color);
}

.submenu li {

    margin: 0;
list-style: none;
}





/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin:0;
}

.slick-slide {
    height: var(--slick-slider-height);
    background-size: cover;
}


/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
}

header, footer {
    flex: none;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    outline: 0 !important;
}

/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 50vh;
}

.map-canvas img {
    
}

@media (max-width: var(--breakpoint-m)) {}
@media (max-width: var(--breakpoint-s)) {}
@media (max-width: var(--breakpoint-xs)) {}
